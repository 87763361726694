.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-title {
  font-size: 68px;
  font-weight: bold;
  font-family: "Roboto Slab", serif;
}

.app-tagline {
  font-size: 16px;
  color: #ccc;
  font-family: "Roboto Slab", serif;
}